export function 
/**
 * Helper method for making POST HTTP requests.
 */
post(uri, form) {
    return Spark.sendForm('post', uri, form);
}
export function 
/**
 * Helper method for making PUT HTTP requests.
 */
put(uri, form) {
    return Spark.sendForm('put', uri, form);
}
export function 
/**
 * Helper method for making PATCH HTTP requests.
 */
patch(uri, form) {
    return Spark.sendForm('patch', uri, form);
}
export function 
/**
 * Send the form to the back-end server.
 *
 * This function will clear old errors, update "busy" status, etc.
 */
sendForm(method, uri, form) {
    return new Promise((resolve, reject) => {
        form.startProcessing();
        axios[method](uri, JSON.parse(JSON.stringify(form)))
            .then(response => {
            form.finishProcessing();
            resolve(response.data);
        })
            .catch(errors => {
            form.setErrors(errors.response.data.errors);
            reject(errors.response.data);
        });
    });
}
export default {
    post,
    put,
    patch,
    /**
     * Helper method for making DELETE HTTP requests.
     */
    delete(uri, form) {
        return Spark.sendForm('delete', uri, form);
    },
    sendForm
};
