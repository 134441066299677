import mixin from "./mixin.js";
import "./filters.js";
import "./forms/bootstrap.js";
import Vue from "vue";
/*
 * Load Vue, the JavaScript framework used by Spark.
 */
if (window.Vue === undefined) {
    window.Vue = Vue;
    window.Bus = new Vue();
}
/**
 * Load Vue Global Mixin.
 */
Vue.mixin(mixin);
