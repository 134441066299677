import Dinero from "dinero.js";
/**
 * Format the given date.
 */
Vue.filter('date', value => {
    return moment.utc(value).local().format('MMMM Do, YYYY');
});
/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
    return moment.utc(value).local().format('MMMM Do, YYYY h:mm A');
});
/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', value => {
    return moment.utc(value).local().locale('en-short').fromNow();
});
/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', value => {
    if (!value && value !== 0) {
        return '';
    }
    return value.toString().charAt(0).toUpperCase()
        + value.slice(1);
});
/**
 * Format the given money value.
 */
Vue.filter('currency', value => {
    return Dinero({
        amount: Math.round(value * 100),
        currency: window.Spark.currency
    }).setLocale(window.Spark.currencyLocale).toFormat('$0,0.00');
});
