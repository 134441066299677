import "bootstrap";
import "vue-bootstrap";
import urijs from "urijs";
import axios from "axios";
import lodash from "lodash";
import moment from "moment";
//import promise from "promise";
import popper from "popper.js";
import jquery from "jquery";

/*
 * Load various JavaScript modules that assist Spark.
 */
window.URI = urijs;
window.axios = axios;
window._ = lodash;
window.moment = moment;
//window.Promise = promise;
window.Popper = popper;
window.__ = (key, replace) => {
    var translation = Spark.translations[key] ? Spark.translations[key] : key;
    _.forEach(replace, (value, key) => {
        translation = translation.replace(':' + key, value);
    });
    return translation;
};
/*
 * Define Moment locales
 */
window.moment.defineLocale('en-short', {
    parentLocale: 'en',
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "1s",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1 month ago",
        MM: "%d months ago",
        y: "1y",
        yy: "%dy"
    }
});
window.moment.locale('en');
/*
 * Load jQuery and Bootstrap jQuery, used for front-end interaction.
 */
if (window.$ === undefined || window.jQuery === undefined) {
    window.$ = window.jQuery = jquery;
}
/**
 * Load Vue if this application is using Vue as its framework.
 */
if ($('#spark-app').length > 0) {
}
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': Spark.csrfToken
};
/**
 * Intercept the incoming responses.
 *
 * Handle any unexpected HTTP errors and pop up modals, etc.
 */
window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response === undefined) {
        return Promise.reject(error);
    }
    switch (error.response.status) {
        case 401:
            window.axios.get('/logout');
            $('#modal-session-expired').modal('show');
            break;
        case 402:
            window.location = '/settings#/subscription';
            break;
    }
    return Promise.reject(error);
});
